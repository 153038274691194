<h1>
  <img src="/assets/uweti-logo.png" alt="Uweti logo">
  <p>
    Web - Networking - Video - Graphics
  </p>
</h1>

<!-- Web / Networking / Video / Graphics -->

<div class="blueprint">
  <div class="gradient"></div>
  <div class="stroke1"></div>
  <div class="stroke2"></div>
  <!--
  <div class="grid"></div>

  <div class="circle"></div>
  <div class="circle delay1"></div>
  -->

  <object data="/assets/blueprint/logo-quotes.svg" class="logo-quotes"></object>

  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="schema" viewBox="-800 -800 1600 1600" height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
    <defs>
      <pattern id="grid" x="0" y="0" width="0.05" height="0.05">
        <image href="/assets/blueprint/blueprint-cells.png" width="250" height="250"/>
      </pattern>
      <filter id="f1">
        <feGaussianBlur id="blur1" in="SourceGraphic" stdDeviation="5" />
      </filter>
      <animate
        href="#blur1"
        id="anim-blur"
        attributeName="stdDeviation"
        values="1;10;1"
        dur="60s"
        keySplines="0.5, 0, 0.5, 1; 0.5, 0, 0.5, 1" calcMode="spline"
        repeatCount="indefinite"
        begin="-30s"
      />
    </defs>

    <rect x="-2500" y="-2500" width="5000" height="5000" fill="url(#grid)" opacity="1" transform="scale(0.8)" class="blend">
      <animateTransform attributeType="XML" attributeName='transform' type="scale" values="1 ; 0.8 ; 1" keyTimes="0 ; 0.5 ; 1" keySplines="0.5, 0, 0.5, 1; 0.5, 0, 0.5, 1" calcMode="spline" dur="60s" repeatCount="indefinite"/>
      <animateTransform attributeType="XML" attributeName='transform' type="rotate" values="10 ; 0 " keyTimes="0 ; 1" keySplines="0, 0, 0.5, 1" calcMode="spline" dur="3s"/>
      <animate attributeName='opacity' values="0 ; 1 " keyTimes="0 ; 1" keySplines="0, 0, 0.5, 1" calcMode="spline" dur="3s"/>
    </rect>

    <g class="blend">
      <g>
        <circle cx="0" cy="0" r="50%" stroke="white" stroke-width="0.5" fill="transparent" stroke-dasharray="6 6"></circle>
        <circle cx="0" cy="-50%" r="2%" stroke="white" stroke-width="1" opacity="0.5" fill="transparent">
          <animateTransform attributeType="XML" attributeName='transform' type="rotate" from="0" to="360" dur="60s" repeatCount="indefinite"/>
        </circle>
        <circle cx="0" cy="-50%" r="3%" stroke="white" stroke-width="1" opacity="0.5" fill="transparent">
          <animateTransform attributeType="XML" attributeName='transform' type="rotate" from="0" to="360" dur="60s" begin="-40s" repeatCount="indefinite"/>
        </circle>
        <animateTransform attributeType="XML" attributeName='transform' type="scale" values="0.5;1;0.5" keyTimes="0 ; 0.5 ; 1" keySplines="0.5, 0, 0.5, 1; 0.5, 0, 0.5, 1" dur="70s" repeatCount="indefinite"/>
      </g>
      <animateTransform attributeType="XML" attributeName='transform' type="rotate" from="0" to="360" dur="120s" repeatCount="indefinite"/>
    </g>

    <g class="blend">
      <circle cx="0" cy="0" r="80%" stroke="white" stroke-width="2" opacity="0.2" fill="transparent">
        <animateTransform attributeType="XML" attributeName='transform' type="scale" values="0.5;1;0.5" keyTimes="0 ; 0.5 ; 1" keySplines="0.5, 0, 0.5, 1; 0.5, 0, 0.5, 1" calcMode="spline" dur="60s" begin="-20s" repeatCount="indefinite"/>
      </circle>
      <animateTransform attributeType="XML" attributeName='transform' type="rotate" from="360" to="0" dur="90s" repeatCount="indefinite"/>
    </g>

    <image href="/assets/blueprint/triangle.svg" x="-1500" y="-1500" opacity="0.3" filter="url(#f1)" width="3000" height="3000" class="">
      <animateTransform attributeType="XML" attributeName='transform' type="scale" values="1 ; 0.8 ; 1" keyTimes="0 ; 0.5 ; 1" keySplines="0.5, 0, 0.5, 1; 0.5, 0, 0.5, 1" calcMode="spline" dur="60s" repeatCount="indefinite"/>
    </image>

  </svg>

</div>

<router-outlet></router-outlet>
